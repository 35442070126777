import React, { useEffect, useMemo, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { toast } from "react-toastify";
import Axios from "axios";
import { DEV } from "../../services/constants";
import AddCustomerDialog from "../customers/AddCustomerDialog";
import { Dialog } from "primereact/dialog";
import { useLocation } from "react-router-dom";
import SofStop from "../../modals/SofStop";
import { Tooltip } from "react-tippy";
import ClipLoader from "react-spinners/ClipLoader";
import "./customers.scss";
import AddFlightDialog from "./AddFlightDialog";
import ScanPackage from "./ScanPackage";
import { handlePutRequest } from "../../services/PutTemplate";

function Flight() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const [selectedRow, setselectedRow] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [brands, setBrands] = useState({});
    const [skip, setSkip] = useState(0);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [flightStatus, setFlightStatus] = useState("sent");
    const [sentPackages, setSentPackages] = useState([]);
    const [ReceivedPackages, setReceivedPackages] = useState([]);
    const [leftPackages, setLeftPackages] = useState([]);
    const [toSend, setToSend] = useState([]);
    const [showingSent, setShowingSent] = useState([]);
    const [showingReceived, setShowingReceived] = useState([]);
    const [showingLeft, setShowingLeft] = useState([]);
    const [selected, setSelected] = useState("sent");

    const menu = useRef(null);
    const breadItems = [{ label: "Home", url: "/" }, { label: "Customers" }];

    const home = { icon: "pi pi-home", url: "/" };
    const history = useHistory();

    const handledClicked = () => {
        history.push("");
    };

    const getBrands = async () => {
        setLoading(true);
        const res = await handleGetRequest(`/specificFlight/${id}`);
        const sentPackages = res?.data?.sentPackages;
        const ReceivedPackages = res?.data?.receivedPackages;
        const leftPackages = res?.data?.leftPackages;
        setShowingSent(sentPackages);
        setShowingReceived(ReceivedPackages);
        setShowingLeft(leftPackages);
        const S = [];
        const R = [];
        const L = [];
        const Sent = sentPackages.map((val) => {
            S.push({
                package: val?.package?._id,
                sendDate: val?.sendDate,
                receiveDate: val?.receiveDate,
            });
        });
        const Received = ReceivedPackages.map((val) => {
            R.push({
                package: val?.package?._id,
                sendDate: val?.sendDate,
                receiveDate: val?.receiveDate,
            });
        });
        const Left = leftPackages.map((val) => {
            L.push({
                package: val?.package?._id,
                sendDate: val?.sendDate,
                receiveDate: val?.receiveDate,
            });
        });
        setSentPackages(S);
        setReceivedPackages(R);
        setLeftPackages(L);
        setBrands(res?.data);
        setLoading(false);
    };
    useMemo(() => {
        getBrands();
    }, [id]);

    console.log(brands);

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-ellipsis-v" className="p-button-rounded mr-2 Elipse_Icon" onClick={(e) => handleActionButton(e, rowData)} aria-controls="popup_menu" aria-haspopup />
            </div>
        );
    };
    const handleActionButton = (e, rowdata) => {
        e.preventDefault();
        history.push(`customer/${rowdata?._id}`);
    };

    const onHideTyreBrandsDialog = () => {
        setShowDialog(false);
    };
    const handledDelete = async () => {
        setShowSoftStop(true);
    };

    const handleskip = (num) => {
        setSkip(num);
    };
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        phone: "",
        date: "",
    });

    const temporary = ["firstName", "lastName", "email", "phone", "role", "date"];

    const handleApplyFilter = async (value, names) => {
        setLoading(true);
        const temp = values;
        // temporary.forEach((item) => {
        //     if (item !== names) {
        //         temp[item] = "";
        //     }
        // });
        // setValues(temp);
        temp[names] = value;
        setValues({ ...values, [names]: value });
        const result = await Axios.get(DEV + "/searchUsers", {
            params: {
                ...temp,
            },
        });
        setBrands(result?.data?.data);
        setLoading(false);
    };

    const handleFilter = (name) => {
        return <input style={{ width: "100%", height: "37px", borderRadius: "5px", border: "none" }} value={values[name]} onChange={(e) => handleApplyFilter(e.target.value, name)}></input>;
    };

    const onsuccess = () => {
        onHideTyreBrandsDialog();
        toast.success("Flight added");
        window.location.reload();
    };

    const [showSoftStop, setShowSoftStop] = useState(false);

    const onHideSoftStop = () => {
        setShowSoftStop(false);
    };

    const onSuccessSoftStop = async () => {
        setShowSoftStop(false);
        if (selectedRow?.length > 0) {
            const selectedIds = selectedRow.map((val) => {
                return val?._id;
            });
            const data = {
                id: selectedIds,
            };
            const res = await dispatch(handlePostRequest(data, "/deleteFlight", true, true));
            if (res?.success) {
                getBrands();
                toast.success("Flight deleted");
                window.location.reload();
            }
        }
    };

    const handleSentPackages = (sentPackages) => {
        setSentPackages(sentPackages);
    };

    const handleReceivedPackages = (ReceivedPackages) => {
        setReceivedPackages(ReceivedPackages);
    };

    const handleLeftPackages = (leftPackages) => {
        setLeftPackages(leftPackages);
    };

    const handleUpdateFlight = async () => {
        // const data = {
        //     id: brands?._id,
        //     sentPackages: sentPackages,
        //     receivedPackages: ReceivedPackages,
        //     leftPackages: leftPackages,
        // };
        // const res = await handlePutRequest(data, "/updateFlight");
        // if (res?.success) {
        //     toast.success("Flight updated successfully");
        //     window.location.reload();
        // }
        window.location.reload();
        setShowDialog(false);
    };

    const nameTemplate = (rowdata) => {
        return (
            <p>
                {rowdata?.package?.name?.firstName} {rowdata?.package?.name?.lastName}
            </p>
        );
    };

    const weightTemplate = (rowdata) => {
        return (
            <p>
                {rowdata?.package?.weight.number} {rowdata?.package?.weight.unit}
            </p>
        );
    };

    console.log(showingSent);

    const handleClose=async()=>{
        const data = {
            id: brands?._id,
            closed: !brands?.closed
        };
        const res = await handlePutRequest(data, "/closeFlight");
        if (res?.success) {
            toast.success("Flight closed successfully");
            window.location.reload();
        }
    }
    return (
        <>
            <Dialog visible={showDialog} header="Scan Package" style={{ width: "650px" }} onHide={() => handleUpdateFlight()}>
                <ScanPackage
                    onHideTyreBrandsDialog={onHideTyreBrandsDialog}
                    onsuccess={onsuccess}
                    id={brands?._id}
                    closed={brands?.closed}
                    flight={brands}
                    handleSentPackages={handleSentPackages}
                    handleReceivedPackages={handleReceivedPackages}
                    handleLeftPackages={handleLeftPackages}
                    sent={sentPackages}
                    Received={ReceivedPackages}
                    left={leftPackages}
                />
            </Dialog>
            <Dialog visible={showSoftStop} style={{ width: "650px" }} onHide={() => setShowSoftStop(false)}>
                <SofStop onHideSoftStop={onHideSoftStop} onSuccessSoftStop={onSuccessSoftStop} type="user"></SofStop>
            </Dialog>

            <div className="Page__Header">
                <div>
                    <h2>Flight {brands?.flightNumber}</h2>
                </div>
                <div className="Top__Btn">
                    <Tooltip
                        // options
                        title="scan packages"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-camera" iconPos="right" onClick={() => setShowDialog(true)} className="Btn__Blue" />
                    </Tooltip>
                    {/* <Tooltip
                        // options
                        title="Delete selected flight"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-trash" iconPos="right" onClick={handledDelete} className="Btn__DarkDelete" style={{ width: "240px" }} />
                    </Tooltip> */}
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 0px" }}>
                <div style={{ display: "flex", columnGap: "5px" }}>
                    <button
                        style={{
                            background: selected === "sent" ? "#2C4870" : "white",
                            color: selected === "sent" ? "white" : "black",
                            border: "1px solid #2C4870",
                            padding: "10px",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                        onClick={() => setSelected("sent")}
                    >
                        Sent
                    </button>
                    <button
                        style={{
                            background: selected === "received" ? "#2C4870" : "white",
                            color: selected === "received" ? "white" : "black",
                            border: "1px solid #2C4870",
                            padding: "10px",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                        onClick={() => setSelected("received")}
                    >
                        Received
                    </button>
                    <button
                        style={{
                            background: selected === "left" ? "#2C4870" : "white",
                            color: selected === "left" ? "white" : "black",
                            border: "1px solid #2C4870",
                            padding: "10px",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                        onClick={() => setSelected("left")}
                    >
                        Left
                    </button>
                </div>
                <div>
                    <button
                        style={{
                            background: brands?.closed  ? "red" : "green",
                            color:"white",
                            border: "1px solid #2C4870",
                            padding: "10px",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                        onClick={()=>handleClose()}
                    >
                        {brands?.closed ? "Open flight" : "Close flight"}
                    </button>
                </div>
            </div>
            {selected === "sent" && (
                <div className="card">
                    <DataTable
                        filterDisplay="row"
                        paginator
                        rows={100}
                        rowsPerPageOptions={[100, 500, 1000]}
                        className="datatable-responsive custom-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                        emptyMessage="No List found."
                        // responsiveLayout="scroll"
                        value={showingSent}
                        selection={selectedRow}
                        onSelectionChange={(e) => setselectedRow(e.value)}
                        sortMode="multiple"
                        loadingIcon="pi pi-spinner"
                        loading={loading}
                        loadingTemplate={<ClipLoader color={"#2C4870"} loading={loading} size={30} />}
                        loadingText="Loading..."
                        style={{ Height: "100px", overflowY: "auto" }}
                    >
                        <Column selectionMode="multiple" style={{ width: "3em" }} />
                        {/* <Column header="Action" body={actionBodyTemplate} /> */}
                        <Column filter field="package.Waybill" header="Waybill" sortable />
                        <Column filter field="package.billTo" header="Package Name" sortable />
                        <Column body={nameTemplate} header="Bill To" />
                        <Column filter field="package.trackingNumber" header="Tracking Number" />
                        <Column filter field="package.deliveryInfo" header="Delivery Information" />
                        <Column body={weightTemplate} header="Weight of package" />
                        <Column filter field="sendDate" header="Date Sent" />
                    </DataTable>
                </div>
            )}
            {selected === "received" && (
                <div className="card">
                    <DataTable
                        filterDisplay="row"
                        paginator
                        rows={100}
                        rowsPerPageOptions={[100, 500, 1000]}
                        className="datatable-responsive custom-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                        emptyMessage="No List found."
                        // responsiveLayout="scroll"
                        value={showingReceived}
                        selection={selectedRow}
                        onSelectionChange={(e) => setselectedRow(e.value)}
                        sortMode="multiple"
                        loadingIcon="pi pi-spinner"
                        loading={loading}
                        loadingTemplate={<ClipLoader color={"#2C4870"} loading={loading} size={30} />}
                        loadingText="Loading..."
                        style={{ Height: "100px", overflowY: "auto" }}
                    >
                        <Column selectionMode="multiple" style={{ width: "3em" }} />
                        {/* <Column header="Action" body={actionBodyTemplate} /> */}
                        <Column filter field="package.Waybill" header="Waybill" sortable />
                        <Column filter field="package.billTo" header="Package Name" sortable />
                        <Column body={nameTemplate} header="Bill To" />
                        <Column filter field="package.trackingNumber" header="Tracking Number" />
                        <Column filter field="package.deliveryInfo" header="Delivery Information" />
                        <Column body={weightTemplate} header="Weight of package" />
                        <Column filter field="sendDate" header="Date Sent" />
                        <Column filter field="receiveDate" header="Date Receive" />
                    </DataTable>
                </div>
            )}
            {selected === "left" && (
                <div className="card">
                    <DataTable
                        filterDisplay="row"
                        paginator
                        rows={100}
                        rowsPerPageOptions={[100, 500, 1000]}
                        className="datatable-responsive custom-datatable"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                        emptyMessage="No List found."
                        // responsiveLayout="scroll"
                        value={showingLeft}
                        selection={selectedRow}
                        onSelectionChange={(e) => setselectedRow(e.value)}
                        sortMode="multiple"
                        loadingIcon="pi pi-spinner"
                        loading={loading}
                        loadingTemplate={<ClipLoader color={"#2C4870"} loading={loading} size={30} />}
                        loadingText="Loading..."
                        style={{ Height: "100px", overflowY: "auto" }}
                    >
                        <Column selectionMode="multiple" style={{ width: "3em" }} />
                        {/* <Column header="Action" body={actionBodyTemplate} /> */}
                        <Column filter field="package.Waybill" header="Waybill" sortable />
                        <Column filter field="package.billTo" header="Package Name" sortable />
                        <Column body={nameTemplate} header="Bill To" />
                        <Column filter field="package.trackingNumber" header="Tracking Number" />
                        <Column filter field="package.deliveryInfo" header="Delivery Information" />
                        <Column body={weightTemplate} header="Weight of package" />
                        <Column filter field="sendDate" header="Date Sent" />
                    </DataTable>
                </div>
            )}
        </>
    );
}

export default Flight;
