import React, { useEffect, useMemo, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useHistory } from "react-router-dom";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";
import { handleGetRequest } from "../../services/GetTemplate";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import mome from "moment-timezone";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";

function ScanPackage({ onHideTyreBrandDialog, onsuccess, id, closed, flight, handleSentPackages, handleReceivedPackages, handleLeftPackages, sent, Received, left }) {
    const [role, setrole] = useState("");
    const [userId, setuserId] = useState("");
    const breadItems = [{ label: "Home", url: "/" }, { label: "Scan" }];
    const home = { icon: "pi pi-home", url: "/" };
    const [data, setData] = useState("Not Found");
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [sentPackages, setSentPackages] = useState([]);
    const [ReceivedPackages, setReceivedPackages] = useState([]);
    const [leftPackages, setLeftPackages] = useState([]);
    const [showSent, setShowSent] = useState([]);
    const [showRecieved, setShowRecieved] = useState([]);

    const dispatch = useDispatch();

    const [packages, setPackages] = useState([]);

    const getPackages = async () => {
        setLoading(true);
        const res = await handleGetRequest(`/searchPackage?flightNumber=${flight?.flightNumber}`);
        setPackages(res?.data);
        setLoading(false);
    };

    useEffect(() => {
        getPackages();
        setSentPackages(sent);
        setReceivedPackages(Received);
        setLeftPackages(left);
    }, []);

    console.log(packages);

    // useEffect(() => {
    //     handleSentPackages(sentPackages);
    //     handleLeftPackages(leftPackages);
    //     handleReceivedPackages(ReceivedPackages);
    // }, [sentPackages, ReceivedPackages, leftPackages]);

    const handleScan = async () => {
        setLoading(true);
        if (closed) {
            // const filteredPackage = packages.filter((item) => item.Waybill === data);
            // if (filteredPackage.length > 0) {
            //     const receivedFiltered = ReceivedPackages.filter((item) => item.package === filteredPackage[0]?._id);
            //     if (receivedFiltered.length > 0) {
            //         toast.error("Package already received");
            //     } else {
            //         const newPackage = {
            //             package: filteredPackage[0]?._id,
            //             sendDate: sentPackages.find((item) => item.package === filteredPackage[0]?._id)?.sendDate,
            //             receiveDate: moment.utc().tz("America/New_York").format("MM/DD/YYYY hh:mm a"),
            //             totalPackage: filteredPackage[0],
            //         };
            //         setShowRecieved([...showRecieved, filteredPackage[0]?.Waybill]);

            //         // Update the received packages
            //         const updatedReceivedPackages = [...ReceivedPackages, newPackage];
            //         setReceivedPackages(updatedReceivedPackages);

            //         // Filter out packages that have been received from sent packages
            //         const updatedLeftPackages = sentPackages.filter((item) => !updatedReceivedPackages.some((rp) => rp.package === item.package));
            //         setLeftPackages(updatedLeftPackages);
            //         toast.success("Package marked as received successfully.");
            //     }
            // }
            const dat={
                id:flight?.flightNumber,
                Waybill:data,
            }
            const result=await dispatch(handlePostRequest(dat,"/addReceivedPackages",true,true));
            if(result!=="error")
            {
                toast.success("Package has been marked received");
                setReceivedPackages([...ReceivedPackages,result?.data]);
                setShowRecieved([...showRecieved,data]);
            }
        } else {
            // const filteredPackage = packages.filter((item) => item.Waybill === data);
            // if (filteredPackage.length > 0) {
            //     const sentFilter = sentPackages.filter((item) => item.package === filteredPackage[0]?._id);
            //     if (sentFilter.length > 0) {
            //         toast.error("Package already added to flight");
            //     } else {
            //         setShowSent([...showSent, filteredPackage[0]?.Waybill]);
            //         setSentPackages([
            //             ...sentPackages,
            //             {
            //                 package: filteredPackage[0]?._id,
            //                 sendDate: mome.utc().tz("America/New_York").format("MM/DD/YYYY hh:mm a"),
            //                 receiveDate: "",
            //                 totalPackage: filteredPackage[0],
            //             },
            //         ]);
            //         toast.success("Package marked as sent successfully.");
            //     }
            // } else {
            //     toast.error("Package does not belong this flight.");
            // }
            if(data.startsWith("Z")) {
                const dat={
                    id:flight?.flightNumber,
                    Waybill:data,
                }
                const result=await dispatch(handlePostRequest(dat,"/addSentPackages",true,true));
                if(result!=="error")
                {
                    toast.success("Package added to flight");
                    setSentPackages([...sentPackages,result?.data]);
                    console.log(result?.data);
                    setShowSent([...showSent,data]);
                }
            }
        }
        setTimeout(() => {
            setLoading(false);
            console.log("go gia");
        }, 1000); // 1000 milliseconds = 1 second
    };
    // console.log(data);

    useEffect(() => {
        if (data !== "Not Found") {
            setLoading(true);
            handleScan();
        }
    }, [data]);

    // console.log("sent", sentPackages);
    return (
        <>
            <p style={{ marginTop: "20px" }}>Place the barcode in front of the camera to scan. {closed ? "Receive package of flight" : "Add Package in flight"}</p>
            <p>Note: The changes gets saved after clicking on cross button</p>
            {!loading && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <BarcodeScannerComponent
                        width={300}
                        height={300}
                        onUpdate={(err, result) => {
                            if (result) setData(result.text);
                            else setData("Not Found");
                        }}
                    />
                </div>
            )}
            {loading && (
                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <ClipLoader color={"#000000"} loading={loading} size={100} />
                </div>
            )}
            {showSent.length > 0 && (
                <div>
                    {showSent?.map((item) => {
                        return <span>{item}, </span>;
                    })}
                </div>
            )}
            {showRecieved.length > 0 && <div>{showRecieved?.map((item) => item + ", ")}</div>}
        </>
    );
}

export default ScanPackage;
